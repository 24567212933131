import React from 'react'
import aboutImg from '../Assets/Images/aboutImage.svg'

const AboutRight = () => {
  return (
    <>
        <img src={aboutImg} className='pr-0 mt-12' alt='about us'/>
    </>
  )
}

export default AboutRight