import React from 'react'
import AboutLeft from '../Components/AboutLeft'
import AboutRight from '../Components/AboutRight'
import Team from './Team'
import Howitworks from './Howitworks'

const About = () => {
    return (
        <>
            <div className="grid py-24 md:grid-cols-2 bg-slate-50">
                <div className="">
                    <AboutLeft />
                </div>
                <div className="pr-0 mr-0">
                    <AboutRight />
                </div>
            </div>
            <Howitworks />
            <Team />
        </>
    )
}

export default About