import React from 'react'
import eighty from '../Assets/Images/80+.svg'
import badge from '../Assets/Images/badge.svg'

import established from '../Assets/Images/2020.svg'
import clients from '../Assets/Images/500.svg'
import total from '../Assets/Images/300.svg'
import frame from '../Assets/Images/Frame8.svg'


const AboutLeft = () => {
    return (
        <>
            <div className='mx-auto pl-12 max-sm:px-6 mt-0'>
                <p className='h-8 w-48 text-base align-middle rounded-md font-semibold text-center' style={{ background: 'rgba(52, 184, 230, 0.05)', color: '#34B8E6' }}>
                    About Company
                </p>
                <p className='text-4xl mt-4 font-semibold'>
                    Financial Assistance with True
                    Purpose
                </p>
                <p className='text-lg my-4' style={{
                    color: "#818F9B",
                    // fontSize: '18px',
                    fontFamily: 'Switzer',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '22px'
                }}>
                    We believe that "Investment for All" should be a right, not a privilege, and that's why we started Shashwat Wealth Private Limited.
                    <br></br>
                    <br></br>
                    Helping individuals better manage their financial situations is the primary focus of Shashwat Wealth. Our business assists its customers in increasing their returns over the course of the long term by directing their investments through the Indian financial markets.
                </p>
                {/* <div className='hidden md:flex'> */}
                    <div className='mt-4 py-4 px-4 rounded-lg h-24 w-full flex flex-row hidden md:flex' style={{ backgroundColor: '#005BA3' }}>
                        <img className='h-16 w-auto flex-none' src={eighty} alt='Eighty Plus' />
                        <p className='text-white grow mx-4 my-4 text-2xl font-bold text-left align-middle sm:mr-0 lg:block'>Years of excellence and experience</p>
                        <img className='h-16 w-auto flex-none' src={badge} alt='Eighty Plus' />
                    </div>
                    <div className='mt-4 py-4 px-4 pl-0 max-sm:px-0 rounded-lg h-24 flex flex-row hidden md:flex' style={{}}>
                        {/* <img className='ml-2 mr-16 h-16 max-sm:h-12' src={established} alt='Eighty Plus' />
                        <img className='mx-4 mr-16 h-16 max-sm:h-12' src={clients} alt='Eighty Plus' />
                        <img className='mx-4 h-16 max-sm:h-12' src={total} alt='Eighty Plus' /> */}
                        <img className='h-auto max-w-full' src={frame} alt='Eighty Plus' />
                        {/* <img className='h-16 ' src={clients} alt='Eighty Plus' /> */}
                        {/* <img className='h-16 ' src={total} alt='Eighty Plus' /> */}
                    </div>
                {/* </div> */}

{/* 
                <div className='hidden md:flex'>
                    <div className='mt-4 py-4 px-4 rounded-lg h-24 w-full flex flex-row' style={{ backgroundColor: '#005BA3' }}>
                        <img className='h-16 w-auto flex-none' src={eighty} alt='Eighty Plus' />
                        <p className='text-white grow mx-4 my-4 text-2xl font-bold text-left align-middle lg:block'>Years of excellence and experience</p>
                        <img className='h-16 w-auto flex-none' src={badge} alt='Eighty Plus' />
                    </div>
                    <div className='mt-4 py-4 px-4 pl-0 rounded-lg h-24 bg-slate-300 flex flex-row md:mr-0'>
                        <img className='ml-2 mr-16 h-16' src={established} alt='Eighty Plus' />
                        <img className='mx-4 mr-16 h-16' src={clients} alt='Eighty Plus' />
                        <img className='mx-4 h-16' src={total} alt='Eighty Plus' />
                    </div>
                </div> */}



            </div>
        </>
    )
}

export default AboutLeft