import React from 'react'
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

const Contact = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        phone: '',
        message: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        emailjs.sendForm('service_qv32eo5', 'template_jazd10h', form.current, 'yolNFjDLA_-uDvYyr')
            .then((result) => {
                // console.log(result.text);
                setTimeout(() => {
                    setFormData({
                        user_name: '',
                        user_email: '',
                        phone: '',
                        message: '',
                    });
                    setIsSubmitting(false);
                }, 1000);
            }, (error) => {
                // console.log(error.text);
                setIsSubmitting(false);
            });
    };
    const handleChange = (e) => {
        const { user_name, value } = e.target;
        setFormData({ ...formData, [user_name]: value });
    };
    return (
        <>
            <div className="max-w-screen-2xl mx-auto px-4 py-4 lg:py-4 relative" style={{ background: 'hsl(0, 0%, 96%)' }}>
                <div className='flex justify-center text-center' >
                    <p className='h-8 w-48 mb-6 text-base align-middle rounded-md font-semibold' style={{ background: 'rgba(52, 184, 230, 0.05)', color: '#34B8E6' }}>
                        Contact Us
                    </p>
                    <br></br>
                </div>


                <div className="mb-32">
                    <div className="flex flex-wrap ">
                        <div className="mb-10 py-8 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
                            <h2 className="mb-6 text-3xl font-bold">Shashwat Wealth Private Limited</h2>
                            <p className="mb-6 text-neutral-500 dark:text-neutral-300">
                                Customers are finding it increasingly difficult to locate the best investments for their hard-earned money as the world of finance is always changing and expanding, making it one of the most dynamic industries in the world.
                                <br></br>
                                <br></br>
                                Our strategy is to ensure that our customers have a better understanding of their finances, are knowledgeable about the various financial solutions available, and are moving in the right direction to meet their long-term monetary objectives
                            </p>
                            {/* <br></br> */}
                            <br></br><br></br>
                            {/* <br></br> */}
                            <p class="mb-2 text-neutral-900 dark:text-neutral-300">
                                306 Ratna High Street, Nr Naranpura Police Chawky, Naranpura, Ahmedabad 13
                            </p>
                            <p class="mb-2 text-neutral-900 dark:text-neutral-300">
                                +91 9662543204
                            </p>
                            <p class="mb-2 text-neutral-900 dark:text-neutral-300">
                                info@Shashwatwealth.in
                            </p>
                        </div>
                        <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
                            <form ref={form} onSubmit={sendEmail}>

                                <div className="mb-4">
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-600">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="user_name"
                                        name="user_name"
                                        // value={formData.user_name}
                                        // onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="user_email" className="block mb-2 text-sm font-medium text-gray-600">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="user_email"
                                        // value={formData.user_email}
                                        // onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-600">
                                        Phone
                                    </label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        // value={formData.phone}
                                        // onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-600">
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        // value={formData.message}
                                        // onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 h-36 resize-none"
                                        required
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                                    disabled={isSubmitting} // Disable the button while submitting
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Contact