import React from 'react'
// import Navbar from '../Components/Navbar'
// import Hero from '../Components/Hero'
import TestHero from '../Components/TestHero'



const Header = () => {
  return (
    <>
        {/* <Navbar/> */}
        {/* <Hero/>  */}
        <TestHero/>
    </>
  )
}

export default Header