import React from 'react'

import '../Styles/teams.css';

import TeamMember1 from '../Assets/Images/team1.png'; // Replace with your image URL
import TeamMember2 from '../Assets/Images/team2.jpeg'; // Replace with your image URL
import TeamMember3 from '../Assets/Images/team3.jpeg'; // Replace with your image URL

const Team = () => {

    const teamMembers = [
        {
            name: 'Chintan Patel',
            role: 'Managing Director',
            image: TeamMember2,
            desc: 'Mr Chintan Patel is the Director of Shashwat Wealth Pvt Ltd. He has over 12 years of experience in the financial services industry. He has an MBA in finance from Gujarat Technological University, and he is known for his deep understanding of distribution practices and their challenges.'
        },
        {
            name: 'Tushar Shastri',
            role: 'Managing Director',
            image: TeamMember1,
            desc: 'Mr Tushar Shastri is the Director of Shashwat Wealth Pvt Ltd. He has over 19 years of rich experience in the distribution of financial products. An MBA in finance from Gujarat University. He is known for his key expertise in personal financial planning, and deep understating of investor behaviour. Tushar Shastri is also a well-known trainer in the mutual fund industry. He has conducted more than 150 trainings and Seminars for distributors and Investors.'
        },
        {
            name: 'Gautam Kasundra',
            role: 'CEO',
            image: TeamMember3,
            desc: 'Mr Gautam Kasundra Shastri is the CEO of Shashwat Wealth Pvt Ltd. He has over 11 years of experience in the financial services industry. He has an MBA in finance from Punjab Technical University, and he is known for his deep understanding of financial products.'
        },
    ];
    return (
        <>
            {/* <div className='flex justify-center text-center' >
                <p className='h-8 w-48 text-base align-middle rounded-md font-semibold' style={{ background: 'rgba(52, 184, 230, 0.05)', color: '#34B8E6' }}>
                    Our Team
                </p>
                <br></br>
            </div> */}
            <p className='text-4xl text-center font-semibold'>
                {/* Let's grow together */}
            </p>
            <div className="bg-gray-100 py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-extrabold text-center text-gray-900 mb-8">Meet Our Team</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {teamMembers.map((member, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-md card">
                                <img src={member.image} alt={member.name} className="w-64 h-96 mx-auto mb-4 rounded-full svg" />
                                <div className='card__content'>
                                    <h3 className="text-lg font-semibold mb-1 card_title">{member.name}</h3>
                                    <p className="text-gray-600 card_description">{member.role}</p>

                                    <br></br>

                                    <p className='card_description'>{member.desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Team