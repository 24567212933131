import React, { useState } from 'react'
import ProdBG from '../Assets/Images/ProdBG.png'
import Icon1 from '../Assets/Images/div.icon1.png';
import Icon2 from '../Assets/Images/div.icon2.png';
import Icon3 from '../Assets/Images/div.icon3.png';
import Card from './Card';

const Products = () => {

    const [iscardOneExpanded, setIscardOneExpanded] = useState(false);

    const toggleExpansion = () => {
        setIscardOneExpanded(!iscardOneExpanded);
    };

    const [iscardTwoExpanded, setIscardTwoExpanded] = useState(false);

    const toggleExpansioncardTwo = () => {
        setIscardTwoExpanded(!iscardTwoExpanded);
    };

    const [iscardThreeExpanded, setIscardThreeExpanded] = useState(false);

    const toggleExpansioncardThree = () => {
        setIscardThreeExpanded(!iscardThreeExpanded);
    };

    const [iscardFourExpanded, setIscardFourExpanded] = useState(false);

    const toggleExpansioncardFour = () => {
        setIscardFourExpanded(!iscardFourExpanded);
    };


    return (
        <>
            <div className="mx-auto p-8 md:px-6 mt-0 bg-#032037" style={{ backgroundImage: `url(${ProdBG}})`, backgroundColor: "#032037" }}>
                <div>
                    <div className='flex justify-center text-center' >
                        <p className='h-8 w-48 text-base align-middle rounded-md font-semibold' style={{ background: 'rgba(52, 184, 230, 0.05)', color: '#34B8E6' }}>
                            Products
                        </p>
                        <br></br>
                    </div>
                    <p className='text-4xl text-center mt-4 font-semibold text-white'>
                        Select Our products
                    </p>
                </div>

                {/* <div className="w-64 mx-auto p-4 border border-2 border-blue-500 rounded-lg bg-opacity-0 bg-white backdrop-blur-md">
                    <div className="flex items-center justify-center">
                        <img src={Icon1} alt="Icon" className="w-12 h-12" />
                    </div>
                    <h3 className="text-lg font-semibold text-center mt-4">Card Title</h3>
                    <p className="text-gray-600 text-center mt-2">Card description goes here.</p>
                </div> */}
                {/* <div className="ml-8 mt-4 grid-cols-1 sm:grid md:grid-cols-3">

                    <div
                        className="w-64 pt-4 ml-10 mt-6 flex flex-col border border-2 border-blue-500 rounded-lg bg-opacity-0 bg-white  shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0">
                        <div className="flex items-center justify-center">
                            <img src={Icon1} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-6">
                            <h5
                                className="mb-2 text-xl font-medium leading-tight text-white dark:text-neutral-50">
                                Card title
                            </h5>
                            <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                                This is a longer card with supporting text below as a natural
                                lead-in to additional content. This content is a little bit
                                longer.
                            </p>
                        </div>
                    </div>


                    <div
                        className="w-64 pt-4 ml-10 mt-6 flex flex-col border border-2 border-blue-500 rounded-lg bg-opacity-0 bg-white  shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0">
                        <div className="flex items-center justify-center">
                            <img src={Icon2} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-6">
                            <h5
                                className="mb-2 text-xl font-medium leading-tight text-white dark:text-neutral-50">
                                Card title
                            </h5>
                            <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                                This is a longer card with supporting text below as a natural
                                lead-in to additional content. This content is a little bit
                                longer.
                            </p>
                        </div>
                    </div>


                    <div
                        className="w-64 pt-4 ml-10 mt-6 flex flex-col border border-2 border-blue-500 rounded-lg bg-opacity-0 bg-white  shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0">
                        <div className="flex items-center justify-center">
                            <img src={Icon3} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-6">
                            <h5
                                className="mb-2 text-xl font-medium leading-tight text-white dark:text-neutral-50">
                                Card title
                            </h5>
                            <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                                This is a longer card with supporting text below as a natural
                                lead-in to additional content. This content is a little bit
                                longer.
                            </p>
                        </div>
                    </div>

                </div> */}




                {/* <Card /> */}

                <div className="flex justify-items-center pt-8 grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-2">

                    {/* <!-- Card basic --> */}
                    {/* <div className="w-64 md:w-50 sm:w-50 h-100 m-2 block rounded-lg bg-white bg-opacity-0 border border-2 border-blue-500 rounded-lg text-center shadow-lg dark:bg-neutral-700">
                        <div className="py-3 px-7 flex items-center justify-center">
                            <img src={Icon1} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-7 text-center text-white">
                            <p className='text-lg font-bold'>Mutual Funds</p>
                            <p className="mt-4 text-sm text-justify text-slate-100/75">
                                If you're just starting out as an investor, you shouldn't be too surprised if more seasoned investors suggest that you limit your investments to mutual funds until you have a firm grasp on how the stock market operates. They are on to something: mutual funds can be wonderful for starting investors who don't want to risk it all on a single stock or spend hours each day researching and selecting firms like us. This is because mutual funds pool the money of many investors into a single pool.
                            </p>
                            <br></br>

                            <h4 className='text-left text-base'>Shashwat Wealth Offers:</h4>
                            <br></br>
                            <ul className='text-left text-sm text-slate-100/75 list-disc'>
                                <li>Equity Fund</li>
                                <li>Debt Fund</li>
                                <li>Hybrid Fund</li>
                                <li>International FOF</li>
                                <li>Investment Solutions</li>
                            </ul>
                        </div>
                    </div> */}

                    <div
                        className={`w-64 md:w-50 sm:w-50 h-100 m-2 block rounded-lg bg-white bg-opacity-0 border border-2 border-blue-500 rounded-lg text-center shadow-lg dark:bg-neutral-700 overflow-hidden ${iscardOneExpanded ? 'h-auto' : 'h-96'}`}
                    >
                        <div className="py-3 px-7 flex items-center justify-center">
                            <img src={Icon1} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-7 text-center text-white">
                            <p className='text-lg font-bold'>Mutual Funds</p>
                            <div>
                                <p className="mt-4 text-sm text-justify text-slate-100/75">
                                    If you're just starting out as an investor,
                                </p>
                            </div>
                            <div className={`mt-4 ${iscardOneExpanded ? 'block' : 'hidden'}`}>
                                <p className='text-sm text-justify text-slate-100/75'>you shouldn't be too surprised if more seasoned investors suggest that you limit your investments to mutual funds until you have a firm grasp on how the stock market operates. They are on to something: mutual funds can be wonderful for starting investors who don't want to risk it all on a single stock or spend hours each day researching and selecting firms like us. This is because mutual funds pool the money of many investors into a single pool.</p>
                                <br></br>
                                <h4 className='text-left text-base'>Shashwat Wealth Offers:</h4>
                                <br></br>
                                <ul className='text-left text-sm text-slate-100/75 list-disc'>
                                    <li>Equity Fund</li>
                                    <li>Debt Fund</li>
                                    <li>Hybrid Fund</li>
                                    <li>International FOF</li>
                                    <li>Investment Solutions</li>
                                </ul>
                            </div>
                            <div className="mt-16">
                                <button
                                    onClick={toggleExpansion}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
                                >
                                    {iscardOneExpanded ? 'Show Less' : 'Learn More'}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Card basic--> */}

                    {/* <!-- Card advanced --> */}
                    <div className={`w-64 md:w-50 sm:w-50 h-100 m-2 block rounded-lg bg-white bg-opacity-0 border border-2 border-blue-500 rounded-lg text-center shadow-lg dark:bg-neutral-700 overflow-hidden ${iscardTwoExpanded ? 'h-auto' : 'h-96'}`}>
                        <div className="py-3 px-7 flex items-center justify-center">
                            <img src={Icon2} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-7 text-center text-white">
                            <p className='text-lg font-bold'>Corporate Fixed Deposits</p>
                            {/* <p className="mt-4 text-sm text-justify text-slate-100/75">
                                Company Fixed Deposit or Corporate Fixed Deposit is the money that 
                            </p> */}
                            <div>
                                <p className="mt-4 text-sm text-justify text-slate-100/75">
                                    Company Fixed Deposit or Corporate Fixed Deposit is the money that
                                </p>
                            </div>
                            <div className={`mt-4 ${iscardTwoExpanded ? 'block' : 'hidden'}`}>
                                <p className='text-sm text-justify text-slate-100/75'>
                                    buyers put into different companies for a set amount of time and at a set rate of interest. Fixed Deposits made with companies are recorded on paper receipts called "Corporate FDRs," which stands for "fixed deposit receipts."
                                    These payments can also be made at financial institutions and Non-Banking Finance Companies (NBFCs). Fixed Deposits made by companies are ruled by Section 58A of the Companies Act. However, these deposits are unsecured, which means that if the company goes bankrupt, you can't sell the receipt or document to get your money back.
                                    From time to time, different companies offer Fixed Deposits (FDs) that you can invest in, and Shashwat Wealth has them all. All you have to do is sign up for our service. We give you a full report on the FDs and tell you why we think you should invest in each one. You can call any Shashwat Wealth office, or you can just let us know and we'll take care of the paperwork at your door. You don't need a demat account with us in order to invest in business FDs, and there are no advisory or facilitation fees, either.
                                </p>
                            </div>
                            <div className="mt-6">
                                <button
                                    onClick={toggleExpansioncardTwo}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
                                >
                                    {iscardTwoExpanded ? 'Show Less' : 'Learn More'}
                                </button>
                            </div>
                        </div>

                    </div>
                    {/* <!-- Card advanced--> */}

                    {/* <!-- Card enterprise --> */}
                    <div className={`w-64 md:w-50 sm:w-50 h-100 m-2 block rounded-lg bg-white bg-opacity-0 border border-2 border-blue-500 rounded-lg text-center shadow-lg dark:bg-neutral-700 overflow-hidden ${iscardThreeExpanded ? 'h-auto' : 'h-96'}`}>
                        <div className="py-3 px-7 flex items-center justify-center">
                            <img src={Icon3} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-7 text-center text-white">
                            <p className='text-lg font-bold'>Financial protection</p>

                            <div>
                                <p className="mt-4 text-sm text-justify text-slate-100/75">
                                    People with a lot of business and insurance knowledge started Shashwat Wealth.
                                </p>
                            </div>
                            <div className={`mt-4 ${iscardThreeExpanded ? 'block' : 'hidden'}`}>
                                <p className="mt-4 text-sm text-slate-100/75 text-justify">
                                    Their goal was to get more people in the country to have health insurance. It is thought that millions of people, small businesses, and big corporations do not have access to or do not know about the right insurance products for their needs. We want to offer our customers a wide range of Life Insurance and General Insurance services to meet all of their needs.
                                    Our goal is to do what's best for our customers, employees, and other owners. To always try to be the best and do more than what our clients expect. To give our clients the right insurance options for their risk management needs and to help them cut their financial losses as much as possible. Our goal is to build an insurance agency that is known all over the country for its great services and hardworking staff.
                                </p>
                            </div>


                            <div className="mt-12">
                                <button
                                    onClick={toggleExpansioncardThree}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
                                >
                                    {iscardThreeExpanded ? 'Show Less' : 'Learn More'}
                                </button>
                            </div>
                        </div>

                    </div>
                    {/* <!-- Card enterprise--> */}

                    {/* <!-- Card enterprise --> */}
                    <div className={`w-64 md:w-50 sm:w-50 h-100 m-2 block rounded-lg bg-white bg-opacity-0 border border-2 border-blue-500 rounded-lg text-center shadow-lg dark:bg-neutral-700 overflow-hidden ${iscardFourExpanded ? 'h-auto' : 'h-96'}`}>
                        <div className="py-3 px-7 flex items-center justify-center">
                            <img src={Icon3} alt="Icon" className="w-24 h-24" />
                        </div>
                        <div className="p-7 text-center text-white">
                            <p className='text-lg font-bold'>Loans</p>

                            <div>
                                <p className="mt-4 text-sm text-slate-100/75 text-justify">
                                    You can get a wide range of financial loan services from Shashwat Wealth, such as home loans,
                                </p>
                            </div>
                            <div className={`mt-4 ${iscardFourExpanded ? 'block' : 'hidden'}`}>
                                <p className="mt-4 text-sm text-slate-100/75 text-justify">
                                    personal loans, auto loans, business loans, and credit cards. This company is privately owned and has offices in Ahmedabad, which is in the state of Gujarat.
                                    We want to be a well-known name in the world of financial loan services so that people can count on us for their investment and financial planning needs. “Shashwat Wealth Services" wants to become a process-oriented financial marketplace for customers by helping them take care of their financial freedom. We do this by giving each of our clients a qualified financial advisor who works only for them.
                                    Putting your money to work in one of our many Financial Services is a great way to make it grow. We help our clients with insurance, investments, loans and finance, portfolio management, retirement planning, and business advice, among other things.
                                    Shashwat Wealth uses technology to provide an easy-to-use, low-cost, high-quality, process-driven, goal-focused tool that helps people build wealth. This lets the company reach a wide range of people from different backgrounds and places. All of this is possible because of how far technology has come. Our business was built on the idea that we could help our clients get richer by giving them high-quality financial management that is free of any conflicts of interest.
                                </p>
                            </div>

                            <div className="mt-12">
                                <button
                                    onClick={toggleExpansioncardFour}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
                                >
                                    {iscardFourExpanded ? 'Show Less' : 'Learn More'}
                                </button>
                            </div>
                        </div>

                    </div>
                    {/* <!-- Card enterprise--> */}

                </div>

            </div>


        </>
    )
}

export default Products