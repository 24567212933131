import React, { useState } from 'react';
import banner from '../Assets/Images/bannerBackground.jpg'
import home from '../Assets/Images/home.jpg'

import { Carousel, initTE } from 'tw-elements';

initTE({ Carousel });

const TestHero = () => {

    const [principal, setPrincipal] = useState('');
    const [rateOfInterest, setRateOfInterest] = useState('');
    const [numYears, setNumYears] = useState('');
    const [numPayments, setNumPayments] = useState('');
    const [maturityAmount, setMaturityAmount] = useState(null);
    const [totalInvestment, setTotalInvestment] = useState(null);
    const [totalWealthGained, setTotalWealthGained] = useState(null);

    // Function to update the number of payments based on the number of years
    const updateNumPayments = (years) => {
        const months = years * 12;
        setNumPayments(months);
    };

    const calculateSIPDetails = () => {
        const p = parseFloat(principal);
        const i = (parseFloat(rateOfInterest) / 12) / 100; // Convert annual interest rate to monthly rate
        const n = parseFloat(numPayments);

        const numerator = (1 + i) ** n - 1;
        const denominator = i;

        const maturity = p * (numerator / denominator) * (1 + i);
        setMaturityAmount(Math.round(maturity));

        const totalInvestmentAmount = p * n;
        setTotalInvestment(Math.round(totalInvestmentAmount));

        const wealthGained = maturity - totalInvestmentAmount;
        setTotalWealthGained(Math.round(wealthGained));
    };

    // Handle changes in the number of years input
    const handleNumYearsChange = (e) => {
        setNumYears(e.target.value);
        updateNumPayments(e.target.value);
    };
    return (
        <>
            <div className="mx-auto md:px-6 mt-0" style={{ backgroundImage: `url(${banner})` }} >
                <section className=" lg:text-left">
                    <div className="px-6 py-12 md:px-0">
                        <div className="flex grid items-center px-10 sm:px-2 md:px-2 gap-12 lg:grid-cols-2">
                            <div className="mt-12 lg:mt-0 text-left">
                                <h1 className="text-5xl leading-tight tracking-tight text-white">
                                    Calculate Your SIP
                                </h1>
                                <p className="text-neutral-400 italic">
                                    Systematic Investment Plan - Emotionally disciplined strategy for regular investments in mutual funds, aimed at long-term wealth creation
                                </p>
                            </div>
                            <div className="mb-12 lg:mb-0 text-warning-50">
                                <div
                                    className="block rounded-lg bg-blue-950 opacity-90 px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-800 md:px-12">
                                    <form>
                                        <div className="grid md:grid-cols-2 md:gap-6">
                                            <div className=''>

                                                <div className='bg-blue-800 p-4 text-center mb-2 rounded-full text-white'>
                                                    <h1>SIP Calculator</h1>
                                                </div>

                                                <div className="relative mb-3 mt-7">
                                                    <input
                                                        type="text"
                                                        className="text-sm md:text-xs sm:text-xs peer m-0 block h-[58px] w-full rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-white focus:outline-none peer-focus:text-primary dark:border-neutral-600 dark:text-neutral-200 dark:focus:border-primary dark:peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
                                                        id="floatingInput"
                                                        placeholder="Monthly Investment"
                                                        value={principal}
                                                        onChange={(e) => setPrincipal(e.target.value)} />
                                                    <label
                                                        for="floatingInput"
                                                        className="text-sm md:text-xs pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                                    >Monthly Investment</label>
                                                </div>

                                                <div className="relative mb-3">
                                                    <input
                                                        type="text"
                                                        className="text-sm md:text-xs sm:text-xs peer m-0 block h-[58px] w-full rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-white focus:outline-none peer-focus:text-primary dark:border-neutral-600 dark:text-neutral-200 dark:focus:border-primary dark:peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
                                                        id="floatingInput"
                                                        placeholder="Number of Years"
                                                        value={numYears}
                                                        onChange={handleNumYearsChange} />
                                                    <label
                                                        for="floatingInput"
                                                        className="text-sm md:text-xs sm:text-xs pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                                    >Number of Years</label>
                                                </div>

                                                <div className="relative mb-3">
                                                    <input
                                                        type="text"
                                                        className="text-sm md:text-xs sm:text-xs peer m-0 block h-[58px] w-full rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-white focus:outline-none peer-focus:text-primary dark:border-neutral-600 dark:text-neutral-200 dark:focus:border-primary dark:peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
                                                        id="floatingInput"
                                                        placeholder="Expected Rate of Return"
                                                        value={rateOfInterest}
                                                        onChange={(e) => setRateOfInterest(e.target.value)} />
                                                    <label
                                                        for="floatingInput"
                                                        className="text-sm md:text-xs sm:text-xs pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                                                    >Expected Rate of Return</label>
                                                </div>


                                                <button type="button" data-te-ripple-init data-te-ripple-color="light"
                                                    onClick={calculateSIPDetails}
                                                    className="inline-block mt-3 w-full rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase max-sm:mb-6 leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                                                    Calculate
                                                </button>
                                            </div>
                                            <div className='rounded-lg sm:p-2 md:p-2 p-8' style={{ backgroundColor: 'rgba(0, 91, 163, 0.40)' }}>
                                                <div className="result-container">
                                                    <div className='text-center text-white'>
                                                        <p className='text-xl'>
                                                            Result
                                                        </p>
                                                        <p className='text-sm'>
                                                            Pre and post tax estimates
                                                        </p>
                                                    </div>
                                                    <div className='p-2 mt-4 rounded-lg text-center h-max sm:h-max md:h-max' style={{ background: 'rgba(52, 184, 230, 0.30)', border: '1px solid rgba(52, 184, 230)' }}>
                                                        <label className='text-xs md:text-xs sm:text-xs'>Total Investment Amount</label>
                                                        <br></br>
                                                        {totalInvestment !== null && (
                                                            <label>{totalInvestment}</label>
                                                        )}
                                                    </div>
                                                    <div className='p-2 mt-4 rounded-lg text-center h-max sm:h-max md:h-max' style={{ background: 'rgba(52, 184, 230, 0.30)', border: '1px solid rgba(52, 184, 230)' }}>
                                                        <label className='text-sm md:text-xs sm:text-xs'>Wealth Gained</label>
                                                        <br></br>
                                                        {totalWealthGained !== null && (
                                                            <label>{totalWealthGained}</label>
                                                        )}
                                                    </div>
                                                    <div className='p-2 mt-4 rounded-lg text-center h-max sm:h-max md:h-max' style={{ background: 'rgba(52, 184, 230, 0.30)', border: '1px solid rgba(52, 184, 230)' }}>
                                                        <label className='text-sm md:text-xs sm:text-xs'>Maturity Value</label>
                                                        <br></br>
                                                        {maturityAmount !== null && (
                                                            <label>{maturityAmount}</label>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



            {/* <!-- Container for demo purpose --> */}
            <div className="container p-16 mx-auto sm:p-16 xs:p-0 md:px-6 bg-gradient-to-b from-blue-50 to-blue-200">
                {/* <!-- Section: Design Block --> */}
                <section className="">
                    {/* <!-- Jumbotron --> */}
                    <div className="container mx-auto text-center lg:text-left xl:px-32">
                        <div className="flex grid items-center lg:grid-cols-2">
                            <div className="mb-12 lg:mb-0">
                                <div
                                    className="w-fit relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                                    <h2 className="mb-8 text-3xl font-bold">We Grow with Customers.</h2>
                                    <p className="mb-8 pb-2 text-neutral-500 dark:text-neutral-300 lg:pb-0">
                                        We provide our customers with financial advice and assist them in putting their goals into action. We want to be our client's financial sanctuary of refuge by utilizing our extensive industry experience. Our compound annual growth rate (CAGR) is 66%, and we consistently provide outstanding performances in a timely and efficient manner.
                                    </p>
                                    <p className="mb-8 pb-2 text-neutral-500 dark:text-neutral-300 lg:pb-0">
                                        We are a financial services company with our headquarters in Ahmedabad and our primary market being India. In both Tier I and Tier II locations, Shashwat Wealth is constantly on the lookout for new business prospects.
                                    </p>

                                    <h2 className='font-bold'>WHAT MAKES US DIFFERENT</h2>

                                    <br></br>

                                    <div className="mx-auto mb-8 flex flex-col md:flex-row md:justify-around lg:justify-between">
                                        <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" className="mr-2 h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            DEEP ASSET CLASS EXPERTISE
                                        </p>

                                        <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" className="mr-2 h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            LONG-TERM PERSPECTIVE
                                        </p>
                                    </div>
                                    <div className="mx-auto mb-8 flex flex-col md:flex-row md:justify-around lg:justify-between">
                                        <p className="w-fit mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" className="mr-2 h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            STRENGTH, STABILITY AND TRUST
                                        </p>

                                        <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" className="mr-2 h-5 w-5">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            BREADTH OF CAPABILITIES
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='hidden sm:block'>
                                <img src={home}
                                    className="w-full rounded-lg shadow-lg dark:shadow-black/20" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* <!-- Jumbotron --> */}
                </section>
                {/* <!-- Section: Design Block --> */}
            </div>




            {/* <div className='p-24 sm:block' style={{ backgroundColor: "#032037" }}>
                <div className="p-16 bg-gradient-to-b from-blue-50 to-blue-200 p-6 rounded-lg shadow-lg border border-gray-300" >

                    <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" className="mr-2 h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Mission
                    </p>
                    <p className="text-gray-600 mt-2 mb-6">
                        At Shashwat Wealth, our primary mission is to provide our customers with effective solutions to their financial challenges.
                    </p>


                    <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" className="mr-2 h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Vision
                    </p>
                    <p className="text-gray-600 mt-2 mb-6">
                        Our vision is to provide clients with professional advice that is clear, straightforward, and clutter-free; this advice should improve the client's quality of life and lead to solutions that can be implemented.
                        After gaining knowledge of the client's particular monetary requirements, we at Shashwat Wealth provide our services by way of one-on-one counselling with each of our customers.
                    </p>


                    <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" className="mr-2 h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Our Strategy
                    </p>
                    <p className="text-gray-600 mt-2 mb-6">
                        Our strategy is to ensure that our customers have a better understanding of their finances, are knowledgeable about the various financial solutions available, and are moving in the right direction to meet their long-term monetary objectives.
                    </p>


                    <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" className="mr-2 h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Custom financing solutions
                    </p>
                    <p className="text-gray-600 mt-2 mb-6">
                        Our clients are able to pursue ambitious expansion objectives and engage in transactions that create value as a direct result of the customized finance solutions we provide for them.
                        <br></br>
                        <br></br>
                        Customers are finding it increasingly difficult to locate the best investments for their hard-earned money as the world of finance is always changing and expanding, making it one of the most dynamic industries in the world.
                    </p>

                    <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" className="mr-2 h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Creativity throughout Finance
                    </p>
                    <p className="text-gray-600 mt-2 mb-6">
                        When it comes to investments, this presents a challenge. In this circumstance, creative financial management would be of great use. It is likely that, in addition to functioning as an investment advisor, it will also build a personalized income management program that supports individuals in discovering the investments that are best suited to their particular circumstances.
                    </p>


                    <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" className="mr-2 h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        The Dedication
                    </p>
                    <p className="text-gray-600 mt-2 mb-6">
                        Shashwat Wealth is dedicated to keeping at the forefront of this trend, as the need for creative methods of managing money is likely to expand in the near future. This is why Shashwat Wealth is devoted to remaining at the forefront of this trend. Not only will we make it our goal to maximize profits in all we do, but also to go above and beyond any and all reasonable expectations.
                    </p>


                </div>

            </div> */}

            <div className="p-8 grid-cols-1 sm:grid md:grid-cols-3 ">

                <div
                    className="m-4 mr-4 block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                    <div className="p-6">
                        <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" className="mr-2 h-5 w-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Mission
                        </p>
                        <p className="text-gray-600 mt-2 mb-6">
                            At Shashwat Wealth, our primary mission is to provide our customers with effective solutions to their financial challenges.
                        </p>
                    </div>
                </div>

                <div
                    className="m-4 mr-4 block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                    <div className="p-6">
                        <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" className="mr-2 h-5 w-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Vision
                        </p>
                        <p className="text-gray-600 mt-2 mb-6">
                            Our vision is to provide clients with professional advice that is clear, straightforward, and clutter-free; this advice should improve the client's quality of life and lead to solutions that can be implemented.
                            <br></br>
                            <br></br>
                            After gaining knowledge of the client's particular monetary requirements, we at Shashwat Wealth provide our services by way of one-on-one counselling with each of our customers.
                        </p>
                    </div>
                </div>

                <div
                    className="m-4 mr-4 block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                    <div className="p-6">
                        <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" className="mr-2 h-5 w-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Our Strategy
                        </p>
                        <p className="text-gray-600 mt-2 mb-6">
                            Our strategy is to ensure that our customers have a better understanding of their finances, are knowledgeable about the various financial solutions available, and are moving in the right direction to meet their long-term monetary objectives.
                        </p>
                    </div>
                </div>

                <div
                    className="m-4 mr-4 block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                    <div className="p-6">
                        <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" className="mr-2 h-5 w-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Custom financing solutions
                        </p>
                        <p className="text-gray-600 mt-2 mb-6">
                            Our clients are able to pursue ambitious expansion objectives and engage in transactions that create value as a direct result of the customized finance solutions we provide for them.
                            <br></br>
                            <br></br>
                            Customers are finding it increasingly difficult to locate the best investments for their hard-earned money as the world of finance is always changing and expanding, making it one of the most dynamic industries in the world.
                        </p>
                    </div>
                </div>

                <div
                    className="m-4 mr-4 block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                    <div className="p-6">
                        <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" className="mr-2 h-5 w-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Creativity through Finance
                        </p>
                        <p className="text-gray-600 mt-2 mb-6">
                            When it comes to investments, this presents a challenge. In this circumstance, creative financial management would be of great use. It is likely that, in addition to functioning as an investment advisor, it will also build a personalized income management program that supports individuals in discovering the investments that are best suited to their particular circumstances.
                        </p>
                    </div>
                </div>

                <div
                    className="m-4 mr-4 block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                    <div className="p-6">
                        <p className="text-3xl font-semibold mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" className="mr-2 h-5 w-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            The Dedication
                        </p>
                        <p className="text-gray-600 mt-2 mb-6">
                            Shashwat Wealth is dedicated to keeping at the forefront of this trend, as the need for creative methods of managing money is likely to expand in the near future. This is why Shashwat Wealth is devoted to remaining at the forefront of this trend. Not only will we make it our goal to maximize profits in all we do, but also to go above and beyond any and all reasonable expectations.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestHero