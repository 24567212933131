import Navbar from './Components/Navbar'
import Footer from './Layouts/Footer';
import About from './Layouts/About';
import Gallery from './Layouts/Gallery';
import Header from './Layouts/Header';
import Howitworks from './Layouts/Howitworks';
// import Partners from './Layouts/Partners';
import Products from './Layouts/Products';
import Service from './Layouts/Service';
import Team from './Layouts/Team';
import Contact from './Layouts/Contact';

import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>

      <Navbar />
      <Routes>
        <Route path='/' element={< Header />}></Route>
        <Route path='/about' element={< About />}></Route>
        <Route path='/service' element={< Service />}></Route>
        <Route path='/howitworks' element={< Howitworks />}></Route>
        <Route path='/products' element={< Products />}></Route>
        <Route path='/team' element={< Team />}></Route>
        <Route path='/events' element={< Gallery />}></Route>
        <Route path='/contact' element={< Contact />}></Route>
      </Routes>
      <Footer />



      {/* <Header /> */}

      {/* <About />

      <Service />

      <Howitworks />

      <Products />

      <Team />

      <Partners />

      <Gallery /> */}

    </>
  );
}

export default App;
