import { Fragment, useState } from 'react'
// import TestHero from '../Components/TestHero'

// import About from '../Layouts/About';
// import Footer from './Layouts/Footer';
// import Gallery from '../Layouts/Gallery';
// import Header from './Layouts/Header';
// import Howitworks from '../Layouts/Howitworks';
// import Partners from '../Layouts/Partners';
// import Products from '../Layouts/Products';
// import Service from '../Layouts/Service';
// import Team from '../Layouts/Team';
// import Contact from '../Layouts/Contact';


import logo from '../Assets/Images/logo.svg';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
    ArrowPathIcon,
    Bars3Icon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';

const products = [
    { name: 'Invest Planning', description: '', href: '/service', icon: ChartPieIcon },
    { name: 'Financial Consultancy', description: '', href: '/service', icon: CursorArrowRaysIcon },
    { name: 'Wealth Management', description: '', href: '/service', icon: FingerPrintIcon },
    { name: 'Mutual Fund', description: '', href: '/service', icon: SquaresPlusIcon },
    { name: 'Goal Planning', description: '', href: '/service', icon: ArrowPathIcon },
    { name: 'Fixed Deposit', description: '', href: '/service', icon: ArrowPathIcon },

]
const callsToAction = [
    // { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
    // { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function click()
{
    console.log("Clicked");
}

const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <>
            <header className="bg-neutral-100">
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img className="h-100 w-auto" src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <Popover.Group className="hidden lg:flex lg:gap-x-8">
                        <Link to="/" className="text-lg font-semibold leading-6 hover:text-blue-600 text-gray-900">
                            Home
                        </Link>
                        
                        <Link to="/about" className="text-lg font-semibold leading-6 hover:text-blue-600 text-gray-900">
                            About Us
                        </Link>

                        <Popover className="relative">
                            <Popover.Button className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600">
                                <Link to="/service">Services</Link>

                                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            </Popover.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                    <div className="p-4">
                                        {products.map((item) => (
                                            <div
                                                key={item.name}
                                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-lg leading-6 hover:text-blue-600"
                                            >
                                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                                </div>
                                                <div className="flex-auto">
                                                    <Link to={item.href} className="block font-semibold text-gray-900">
                                                        {item.name}
                                                        <span className="absolute inset-0" />
                                                    </Link>
                                                    <p className="mt-1 text-gray-600">{item.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                        {callsToAction.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className="flex items-center justify-center gap-x-2.5 p-3 text-lg font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                                            >
                                                <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>

                        {/* <Link to="/howitworks" className="text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600">
                            How it works
                        </Link> */}
                        <Link to="/products" className="text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600">
                            Products
                        </Link>
                        {/* <Link to="/team" className="text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600">
                            Our Team
                        </Link> */}
                        <Link to="/events" className="text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600">
                            Events/Gallery
                        </Link>
                        <Link to="/contact" className="text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600">
                            Contact Us
                        </Link>
                        {/*
                        <a href="/" className="text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600">
                            Career
                        </a> */}
                    </Popover.Group>
                    {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="/" className="text-lg font-semibold leading-6 text-gray-900">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div> */}
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-10" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    className="h-8 w-auto"
                                    src={logo}
                                    alt=""
                                />
                            </Link>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    <Link
                                        to="/"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    // onClick={click}
                                    >
                                        Home
                                    </Link>
                                    <Link
                                        to="/about"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    >
                                        About Us
                                    </Link>

                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600">
                                                    <Link to='/service'>
                                                        Services
                                                    </Link>
                                                    <ChevronDownIcon
                                                        className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                        aria-hidden="true"
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[...products, ...callsToAction].map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as="a"
                                                            to={item.href}
                                                            className="block rounded-lg py-2 pl-6 pr-3 text-lg font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                    {/* <Link
                                        to="/howitworks"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    >
                                        How it works
                                    </Link> */}
                                    <Link
                                        to="/products"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    >
                                        Products
                                    </Link>
                                    {/* <Link
                                        to="/team"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    >
                                        Our Team
                                    </Link> */}
                                    <Link
                                        to="/events"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    >
                                        Events/Gallery
                                    </Link>
                                    <Link
                                        to="/contact"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    >
                                        Contact Us
                                    </Link>
                                </div>
                                {/* <div className="py-6">
                                    <a
                                        href="/"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:text-blue-600"
                                    >
                                        Log in
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

            {/* <section id='home'>
                <TestHero />
            </section>

            <section id='about'>
                <About />
            </section>

            <section id='service'>
                <Service />
            </section>

            <section id='howitworks'>
                <Howitworks />
            </section>

            <section id='product'>
                <Products />
            </section>

            <section id='team'>
                <Team />
            </section>

            <section id='partners'>
                <Partners />
            </section>

            <section id='gallery'>
                <Gallery />
            </section>

            <section id='contact'>
                <Contact />
            </section> */}

        </>
    )
}

export default Navbar