import React from 'react'

import img1 from '../Assets/Images/gi1.jpeg'
import img2 from '../Assets/Images/gi2.jpeg';
import img3 from '../Assets/Images/gi3.jpeg';
import img4 from '../Assets/Images/gi4.jpeg';
import img5 from '../Assets/Images/gi5.jpeg'


const Gallery = () => {
    return (
        <>
            <div class="max-w-screen-2xl mx-auto px-4 py-16 lg:py-24 relative bg-white">
                <div className='flex justify-center text-center' >
                    <p className='h-8 w-48 mb-6 text-base align-middle rounded-md font-semibold' style={{ background: 'rgba(52, 184, 230, 0.05)', color: '#34B8E6' }}>
                        Events/Gallery
                    </p>
                    <br></br>
                </div>
                {/* <p className='text-4xl text-center mt-4 font-semibold'>
                    Let's grow together
                </p> */}

                <div class="flex flex-col md:flex-row gap-2">
                    <div class="flex flex-1 flex-col">
                        <div class="flex flex-1 flex-col">
                            {/* <!-- img_01 --> */}
                            <img class="object-cover h-auto max-w-full" src={img5} alt='' />
                        </div>
                    </div>
                    <div class="flex flex-1">
                        <div class="grid grid-cols-2 gap-2">
                            <div>
                                {/* <!-- img_02 --> */}
                                <img class="object-cover h-auto max-w-full" src={img2} alt='' />
                            </div>
                            <div>
                                {/* <!-- img_03 --> */}
                                <img class="object-cover h-auto max-w-full" src={img3} alt='' />
                            </div>
                            <div>
                                {/* <!-- img_04 --> */}
                                <img class="object-cover h-auto max-w-full" src={img4} alt='' />
                            </div>
                            <div>
                                {/* <!-- img_05 --> */}
                                <img class="object-cover h-auto max-w-full" src={img1} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery