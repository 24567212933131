import React from 'react'
import hiw from '../Assets/Images/hiw.svg'

import { Link } from 'react-router-dom'

const Howitworks = () => {
    return (
        <>
            <div>
                <div className='flex justify-center text-center' >
                    <p className='h-8 w-48 text-base align-middle rounded-md font-semibold' style={{ background: 'rgba(52, 184, 230, 0.05)', color: '#34B8E6' }}>
                        How it works
                    </p>
                    <br></br>
                </div>
                <p className='text-4xl text-center mt-4 font-semibold'>
                    Start Investment Planning in 3 Steps
                </p>
            </div>
            {/* <!-- Container for demo purpose --> */}
            <div className="container pt-6 px-16 mx-auto">

                {/* <!-- Section: Design Block --> */}
                <section className="mb-32 text-gray-800 ">
                    <div className="grid gap-x-6 lg:gap-x-12 md:grid-cols-3">
                        <div className="mb-12 md:mb-0">
                            <div className='flex'>
                                <Link to='/contact' >
                                    <img className='h-12 w-auto' src={hiw} alt='Eighty Plus' />
                                </Link>
                                <h5 className="text-lg font-medium mt-2 ml-4">Reach out</h5>
                            </div>
                            <p className="text-gray-500 mt-4">
                                Give us a call or drop us an email. We are here to help you begin or multiply your investments with profits assured.
                            </p>
                        </div>

                        <div className="mb-12 md:mb-0">
                            <div className='flex'>
                                <Link to='/contact' >
                                    <img className='h-12 w-auto' src={hiw} alt='Eighty Plus' />
                                </Link>
                                <h5 className="text-lg font-medium mt-2 ml-4">Set Goals</h5>
                            </div>
                            <p className="text-gray-500 mt-4">
                                Set your financial goals and discuss with us. It will help us in setting your investment portfolio.
                            </p>
                        </div>

                        <div className="mb-12 md:mb-0">
                            <div className='flex'>
                                <Link to='/contact' >
                                    <img className='h-12 w-auto' src={hiw} alt='Eighty Plus' />
                                </Link>
                                <h5 className="text-lg font-medium mt-2 ml-4">Make that decision</h5>
                            </div>
                            <p className="text-gray-500 mt-4">
                                How much financial risk are you willing to take? Make that decision and we shall surely help you in that.
                            </p>
                        </div>
                    </div>
                </section>
                {/* <!-- Section: Design Block --> */}

            </div>
            {/* <!-- Container for demo purpose --> */}


        </>
    )
}

export default Howitworks